import React from "react";
import Logo from "../../assets/Footer/Logo.png";
import "../Footer/Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = ({ activeTab, handleTabClick }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        const scrollToElement = (id) => {
            const element = document.getElementById(id);

            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        };

        const id = "Services";

        if (window.location.pathname === "/") {
            scrollToElement(id);
        } else {
            navigate("/");
            setTimeout(() => {
                scrollToElement(id);
            }, 100);
        }
    };
    return (
        <section className="footer-section">
            <div className="flex flex-col gap-y-16 py-10">
                <div className="grid grid-cols-1 gap-y-10 md:grid-cols-2 lg:grid-cols-4 gap-x-4 md:gap-x-8 lg:gap-x-5 xl:gap-x-24 mx-4 md:mx-16 lg:mx-24">
                    <div className="flex flex-col gap-y-5">
                        <div className="header-content-footer">
                            <img src={Logo} alt="logo-protonema" className="w-40" />
                        </div>
                        <div className="content-footer flex flex-col gap-y-2">
                            <p className="text-base">
                                Protonema is engaged in IT Services, established in 2013, focusing on Business
                                Consulting, Manage Operation, and Software Development.
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-5 lg:gap-y-10">
                        <div className="header-content-footer">
                            <h2 className="text-lg font-bold title-footer">SERVICES</h2>
                        </div>
                        <div className="content-footer flex flex-col gap-y-2">
                            <p
                                onClick={() => {
                                    handleTabClick(1);
                                    handleClick();
                                }}
                                className="cursor-pointer hover:text-[#2AC2DA]"
                            >
                                Business Consulting
                            </p>
                            <p
                                onClick={() => {
                                    handleTabClick(2);
                                    handleClick();
                                }}
                                className="cursor-pointer hover:text-[#2AC2DA]"
                            >
                                Manage Operation
                            </p>
                            <p
                                onClick={() => {
                                    handleTabClick(3);
                                    handleClick();
                                }}
                                className="cursor-pointer hover:text-[#2AC2DA]"
                            >
                                Software Development
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-5 lg:gap-y-10">
                        <div className="header-content-footer">
                            <h2 className="text-lg font-bold title-footer">ABOUT US</h2>
                        </div>
                        <div className="content-footer flex flex-col gap-y-2">
                            {/* direct to more about */}
                            <a href="/more" className="hover:text-[#2AC2DA]">
                                <p>Overview</p>
                            </a>
                            <a href="/khidma" className="hover:text-[#2AC2DA]">
                                <p>About Khidma</p>
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-5 lg:gap-y-10">
                        <div className="header-content-footer">
                            <h2 className="text-lg font-bold title-footer">CONTACT INFO</h2>
                        </div>
                        <div className="content-footer flex flex-col gap-y-2">
                            <p>
                                L'Avenue Office Tower, 12th Floor Unit C, Jl. Raya Pasar Minggu, Kav.16 Pancoran,
                                Jakarta Selatan DKI Jakarta. 12780
                            </p>
                            <p>
                                <span className="gmap">
                                    <a href={"https://maps.app.goo.gl/pxcFTz4pEcccH4VaA"}>Visit Google Map</a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="text-center text-[#595454]">
                    &copy; 2024 Your{" "}
                    <a href="/" className="copyright">
                        Protonema
                    </a>{" "}
                    All rights reserved.
                </div>
            </div>
        </section>
    );
};

export default Footer;
