import React from "react";
import "../OurContact/OurContact.css";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { LuPhone } from "react-icons/lu";

const OurContact = () => {
  return (
    <section id="ContactUs" className="mx-8 lg:mx-16 xl:mx-20">
      <div className="text-center">
        <h1 className="xl:text-[49px] lg:text-[49px] md:text-[22px] text-[20px] text-[#17A2B8] font-semibold">
          Our Contacts
        </h1>
        <p
          data-aos="fade-up"
          data-aos-duration="800"
          className="text-gray-100-main sm:justify-items-center mb-16 xl:text-[25px] "
        >
          <span className="font-bold">Get In</span> Touch.
        </p>
      </div>
      <div className="contact-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          className="flex gap-x-3 items-center lg:justify-center"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          <div className="icon">
            <IoLocationOutline />
          </div>
          <p className="text-xs lg:text-[14px] xl:text-[16px] font-normal text-[#433E3E] leading-loose">
            L'Avenue Office Tower, 12th Floor Unit C, Jl. Raya Pasar Minggu,
            Kav.16Pancoran, Jakarta SelatanDKI Jakarta. 12780
          </p>
        </div>
        <div
          className="flex gap-x-3 items-center lg:justify-center"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div className="icon">
            <MdOutlineMailOutline />
          </div>
          <p className=" text-xs lg:text-[16px] font-normal text-[#433E3E]">
            info@protonema.co.id
          </p>
        </div>
        <div
          className="flex gap-x-3 items-center lg:justify-center"
          data-aos="fade-left"
          data-aos-duration="800"
        >
          <div className="icon">
            <LuPhone />
          </div>
          <p className="text-xs lg:text-[16px] font-normal text-[#433E3E]">
            021 80667022
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurContact;
