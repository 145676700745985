import React, { useState } from "react";
import About from "./components/AboutUs/About";
import Footer from "./components/Footer/Footer";
import Hero from "./components/HeroSection/Hero";
import Navbar from "./components/Navbar/Navbar";
import OurContact from "./components/OurContact/OurContact";
import Service from "./components/ServiceSection/Service";
import WhyUs from "./components/WhyUsSection/WhyUs";
import "./App.css";
function App() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      <Service activeTab={activeTab} handleTabClick={handleTabClick} />
      <WhyUs />
      <OurContact />
      <Footer activeTab={activeTab} handleTabClick={handleTabClick} />
    </div>
  );
}

export default App;
