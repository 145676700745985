import React, { useState, useEffect } from "react";
import "../../App.css";
import KhidmaLogo from "../../assets/Nav/khidma_logo.png";

function NavbarKhidma() {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        const updateWindowDimensions = () => {
            setDesktop(window.innerWidth > 768);
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    return (
        <nav className="flex flex-row mx-auto max-w-6xl justify-between items-center my-8 px-4 md:px-12">
            <img src={KhidmaLogo} alt="Logo" className="w-40 h-auto" />
            {isDesktop ? (
                <div className="flex flex-row gap-x-10 items-center">
                    <a href="/" className="text-base hover:text-[#557C55] hover:font-semibold">
                        Home
                    </a>
                    <a
                        href="mailto:khidma.service@protonema.co.id"
                        className="text-base font-semibold bg-[#557C55] text-white py-3 px-6 rounded-full hover:bg-[#416341]"
                    >
                        Contact Us
                    </a>
                </div>
            ) : (
                <div className="flex flex-col items-end">
                    <button className="text-3xl focus:outline-none" onClick={toggleMobileMenu}>
                        &#9776;
                    </button>
                    {showMobileMenu && (
                        <div className="absolute top-16 right-4 bg-white shadow-md rounded-md p-4">
                            <a href="/" className="block py-2 hover:text-[#557C55] hover:font-semibold">
                                Home
                            </a>
                            <a
                                href="mailto:khidma.service@protonema.co.id"
                                className="block font-semibold bg-[#557C55] text-white py-2 px-4 rounded-full hover:bg-[#416341]"
                            >
                                Contact Us
                            </a>
                        </div>
                    )}
                </div>
            )}
        </nav>
    );
}

export default NavbarKhidma;
