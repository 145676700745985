import React from "react";
import FooterKhidma from "../components/Footer/FooterKhidma.jsx";
import NavbarKhidma from "../components/NavbarKhidma/NavbarKhidma.jsx";
import OurContactKhidma from "../components/OurContact/OurContactKhidma.jsx";
import AboutKhidma from "../components/AboutUs/AboutKhidma.jsx";
import WhyChooseUs from "../components/WhyUsSection/WhyChooseUs.jsx";
import MainFeatures from "../components/MainFeatures/MainFeatures.jsx";
import { useEffect } from "react";
const KhidmaAbout = () => {
    useEffect(() => {
        document.title = "Tentang Khidma";
    });
    return (
        <div>
            <NavbarKhidma />
            <AboutKhidma />
            <MainFeatures />
            <WhyChooseUs />
            <div className="mx-auto max-w-6xl">
                <OurContactKhidma />
            </div>
            <FooterKhidma />
        </div>
    );
};

export default KhidmaAbout;
