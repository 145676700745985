import "../../components/HeroSection/Hero.css";
import HeroImage from "../../assets/Hero/ImageHero.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const HeroComponent = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    document.querySelectorAll(".has-animation").forEach((element, index) => {
      setTimeout(() => {
        element.classList.add("animate-in");
      }, element.dataset.delay);
    });
  }, []);
  return (
    <section
      className="hero-section grid grid-cols-12 items-center justify-center w-full mt-40"
      id="Home"
    >
      <div className="col-start-2 col-end-12 md:col-end-6">
        <span className="font-bold xl:text-5xl lg:text-5xl md:text-4xl	sm:text-4xl		 capitalize">
          <h1
            data-aos="fade-right"
            data-aos-duration="1000"
            className="content has-animation animation-ltr"
            data-delay="10"
          >
            Your Consulting Partner for{" "}
            <span className=" font-bold span"> Growth</span>{" "}
          </h1>
        </span>
        <p
          data-delay="1000"
          data-aos="fade-up"
          data-aos-duration="1000"
          className="has-animation animation-rtl mt-5 md:mt-10 xl:text-lg	lg:text-base md:text-base sm:text-sm hero-desc"
        >
          Our support and service will help you to grow your business
        </p>
        <button
          data-aos="zoom-in"
          data-aos-duration="1000"
          className="text-white py-2 px-4 rounded-full mt-5 md:mt-10 capitalize"
        >
          <a href="mailto:info@protonema.co.id"> Let's Talk!</a>
        </button>
      </div>
      <div className="col-start-2 col-end-12 md:col-start-9 md:col-end-12">
        <div data-aos="fade-left" className="flex mt-10">
          <img
            data-aos="fade-left"
            data-aos-duration="1000"
            src={HeroImage}
            className="w-full md:max-w-full rounded-md"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default HeroComponent;
