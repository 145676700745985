import React from "react";
import AppKhidma from "../../assets/MainFeatures/Image-App-Khidma.png";
import Dam from "../../assets/MainFeatures/icon-dam.png";
import Catering from "../../assets/MainFeatures/icon-catering.png";
import Valas from "../../assets/MainFeatures/icon-valas.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const MainFeatures = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="flex flex-col lg:flex-row justify-center items-center gap-y-8 lg:gap-x-10 mx-auto max-w-6xl px-4 md:px-12">
            <div className="flex flex-col gap-y-10 ">
                <div className="flex flex-col gap-y-5" data-aos="fade-down" data-aos-duration="1000" data-delay="10">
                    <h2 className="font-bold text-[28px] lg:text-[39px] text-[#433E3E]">Fitur Utama</h2>
                    <p className="font-normal text-base lg:text-[20px] text-[#433E3E]">
                        Di bawah ini adalah layanan unggulan yang kami tawarkan:
                    </p>{" "}
                </div>
                <div className="flex flex-col gap-y-8" data-aos="fade-right" data-aos-duration="1000" data-delay="10">
                    <div className="flex flex-row gap-x-5">
                        <div>
                            <img src={Dam} alt="Icon Dam" className="max-w-[60px] h-auto" />
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <h4 className="text-lg lg:text-xl font-bold">Dam</h4>
                            <p className="text-sm lg:text-base">Dam merupakan jasa pemesanan kambing dam dan qurban.</p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-5">
                        <div>
                            <img src={Catering} alt="Icon Catering" className="max-w-[60px] h-auto" />
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <h4 className="text-lg lg:text-xl font-bold">Katering</h4>
                            <p className="text-sm lg:text-base">Katering merupakan jasa pemesanan makanan siap saji.</p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-5">
                        <div>
                            <img src={Valas} alt="Icon Valas" className="max-w-[60px] h-auto" />
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <h4 className="text-lg lg:text-xl font-bold">Valas (Valuta Asing)</h4>
                            <p className="text-sm lg:text-base">
                                Valas merupakan jasa penukaran mata uang rupiah (Rp) atau dollar (USD) menjadi Riyal
                                (SAR) dan sebaliknya.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <img
                src={AppKhidma}
                alt="Image App Khidma"
                className="w-[456px] h-auto"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-delay="10"
            />
        </div>
    );
};

export default MainFeatures;
