import React from "react";
import HeroImage from "../../assets/Hero/hero_more.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const HeroMore = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <section
            id="Home"
            className="HeroMore flex flex-col gap-y-20 px-4 md:px-12 max-w-6xl mx-auto mt-40 scroll-mt-40"
        >
            <div className="grid grid-cols-1 gap-y-10 md:gap-y-20 lg:grid-cols-2">
                <div className="flex items-center">
                    <h1
                        className="text-4xl md:text-5xl font-bold text-[#433E3E]"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                    >
                        More About <span className="text-[#17A2B8] leading-normal">Protonema</span>
                    </h1>
                </div>
                <div className="illustrasition" data-aos="fade-left" data-aos-duration="1000">
                    <img src={HeroImage} alt="IlustrasitionHeroImage" />
                </div>
            </div>
            <div className="hidden lg:flex justify-end" data-aos="fade-left" data-aos-duration="1000">
                <svg width="330" height="64" viewBox="0 0 330 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="35.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="64.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="93.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <ellipse cx="122" cy="7.0177" rx="6" ry="6.5" fill="#97F1FF" />
                    <circle cx="150.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="179.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <ellipse cx="208" cy="7.0177" rx="6" ry="6.5" fill="#97F1FF" />
                    <circle cx="236.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="265.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="294.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="323.5" cy="7.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="6.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="35.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="64.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="93.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <ellipse cx="122" cy="32.0177" rx="6" ry="6.5" fill="#97F1FF" />
                    <circle cx="150.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="179.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <ellipse cx="208" cy="32.0177" rx="6" ry="6.5" fill="#97F1FF" />
                    <circle cx="236.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="265.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="294.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="323.5" cy="32.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="6.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="35.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="64.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="93.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <ellipse cx="122" cy="57.0177" rx="6" ry="6.5" fill="#97F1FF" />
                    <circle cx="150.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="179.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <ellipse cx="208" cy="57.0177" rx="6" ry="6.5" fill="#97F1FF" />
                    <circle cx="236.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="265.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="294.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                    <circle cx="323.5" cy="57.0177" r="6.5" fill="#97F1FF" />
                </svg>
            </div>
        </section>
    );
};

export default HeroMore;
