import React, { useState, useEffect } from "react";
import "../../components/AboutUs/About.css";
import AboutImage from "../../assets/About/About.png";
import Decoration from "../../assets/About/Elipses.png";

const About = () => {
  const [clientsCount, setClientsCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);

  const targets = {
    clients: 20,
    projects: 10,
    employee: 50,
  };

  const counterInterval = 150;

  useEffect(() => {
    startCounter("clients", setClientsCount, targets.clients);
    startCounter("projects", setProjectsCount, targets.projects);
    startCounter("employee", setEmployeeCount, targets.employee);
  }, [targets.clients, targets.employee, targets.projects]);

  const startCounter = (counterName, setCounter, target) => {
    let currentCount = 0;

    const counterIntervalId = setInterval(() => {
      currentCount++;
      setCounter(currentCount);

      if (currentCount === target) {
        clearInterval(counterIntervalId);
      }
    }, counterInterval);
  };

  useEffect(() => {
    document.querySelectorAll(".has-animation").forEach((element, index) => {
      setTimeout(() => {
        element.classList.add("animate-in");
      }, element.dataset.delay);
    });
  }, []);

  return (
    <section className="about-section font-[Montserrat] md:px-10 lg:px-24 xl:px-32 px-4 mb-10">
      <div className="mt-11" data-aos="fade-right" data-aos-duration="1000">
        <img
          src={Decoration}
          alt="Decoration"
          className="w-[330px] h-auto hidden md:block"
        />
      </div>
      <div
        id="About"
        className="md:py-10 lg:py-24 xl:py-32 py-4 flex flex-col md:flex-row w-full md:gap-x-[60px] lg:gap-x-[116px] items-center justify-between"
      >
        <div
          className="flex flex-row items-center justify-center w-full md:w-1/2"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <img
            src={AboutImage}
            alt="About"
            className="illustrasion w-full h-auto"
          />
        </div>
        <div className=" flex flex-col gap-y-10 xl:gap-y-[59px] w-full md:w-1/2">
          <div className="flex flex-col gap-y-6 xl:gap-y-10">
            <p
              className="text-[20px] md:text-[22px] xl:text-[31px] font-semibold text-[#433E3E] leading-8 md:leading-[35px] lg:leading-[46px]"
              data-aos="fade-down"
              data-aos-duration="500"
            >
              Our 11 years working experience to take care of your business
              goal.
            </p>
            <p
              className="text-xs lg:text-[16px] font-normal text-[#433E3E] leading-5 lg:leading-[25px] text-justify"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              PT. Protonema was established on July 3, 2013. Protonema was
              founded by Mr. Mochamad Taat Baryanto who also served as the
              Company's President Director. Protonema is engaged in IT Services
              company focusing on Business Consulting, Manage Operation, and
              Software Development.
            </p>
            <div
              className="w-full md:w-fit mt-4 bg-[#17A2B8] px-5 py-3 xl:px-8 xl:py-4 hover:bg-[#006474] rounded-2xl md:rounded-[40px] text-center"
              data-aos="flip-down"
              data-aos-duration="1000"
            >
              <a
                href="/more"
                className=" text-white font-semibold  text-[15] xl:text-[20px] "
              >
                More About Protonema
              </a>
            </div>
          </div>
          <div className="flex mx-auto md:mx-0 flex-row gap-x-10  lg:gap-x-[80px]">
            <div className="flex flex-col items-center">
              <p
                className="text-[25px] md:text-[30px] xl:text-[49px] font-semibold text-[#17A2B8]"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                +{clientsCount}
              </p>
              <p
                className="text-[16px] md:text-[10] xl:text-[20px] font-semibold text-[#433E3E]"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Clients
              </p>
            </div>
            <div
              className="flex flex-col items-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <p className="text-[25px] md:text-[30px] xl:text-[49px] font-semibold text-[#17A2B8]">
                +{projectsCount}
              </p>
              <p className="text-[16px] md:text-[10] xl:text-[20px] font-semibold text-[#433E3E]">
                Projects
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p
                className="text-[25px] md:text-[30px] xl:text-[49px] font-semibold text-[#17A2B8]"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                +{employeeCount}
              </p>
              <p
                className="text-[16px] md:text-[10] xl:text-[20px] font-semibold text-[#433E3E]"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Employee
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
