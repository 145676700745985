import React from "react";
import VisionImage from "../../assets/Vision/Vision.png";
const Vision = () => {
    return (
        <section
            id="Vision"
            className="vision max-w-6xl mx-auto flex flex-col md:flex-row lg:justify-between gap-y-10 gap-x-8 px-4 md:px-12 items-center mt-16 scroll-mt-20"
        >
            <div className="flex flex-col gap-y-8 lg:max-w-xl text-[#433E3E]">
                <h2 className="text-4xl lg:text-5xl font-bold" data-aos="fade-up" data-aos-duration="1000">
                    Vision
                </h2>
                <p className="text-sm lg:leading-relaxed lg:text-base" data-aos="fade-right" data-aos-duration="1000">
                    To be a leading IT company that is competitive by providing leading services in producing IT
                    products and services as the best, integrated, professionally managed solution and prioritizing
                    customer satisfaction for sustainable relationships.
                </p>
            </div>
            <div className="illustrasion lg:max-w-[424px]" data-aos="fade-left" data-aos-duration="1000">
                <img src={VisionImage} alt="VisionImage" />
            </div>
        </section>
    );
};

export default Vision;
