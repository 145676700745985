import React from "react";
import WhyKhidmaImage from "../../assets/WhyUs/why-khidma.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const WhyChooseUs = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div className="flex flex-col md:flex-row gap-y-5 md:gap-x-10 xl:gap-x-28 mx-auto max-w-6xl px-4 md:px-12  justify-start  items-center py-28">
                <div
                    className="w-full md:w-1/2 items-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-delay="10"
                >
                    <img src={WhyKhidmaImage} alt="WhyKhidma" className="max-w-full w-full" />
                </div>
                <div
                    className="flex flex-col gap-y-2 lg:gap-y-5  w-full md:w-1/2"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-delay="10"
                >
                    <h2 className="font-bold text-[28px]  lg:text-[39px] text-[#433E3E]">Mengapa Memilih Khidma?</h2>
                    <p className="font-normal text-sm  lg:text-[20px] text-[#433E3E] leading-6 xl:leading-8">
                        Khidma memiliki berbagai macam fitur yang dapat memudahkan dan memenuhi kebutuhan para Jemaah
                        Haji selama di Tanah Suci. Seperti penukaran mata uang yang dapat membantu Jemaah dalam
                        bertransaksi tanpa harus sulit mencari penukaran mata uang, serta memenuhi kebutuhan makanan
                        atau produk Indonesia yang diinginkan Jemaah, dan juga melakukan pemesanan kambing dam dan
                        qurban secara cepat dan mudah. Kami berkomitmen untuk terus memberikan pengalaman terbaik
                        melalui aplikasi kami. Jadi nantikan inovasi-inovasi kami yang akan datang.
                    </p>
                </div>
            </div>
        </>
    );
};

export default WhyChooseUs;
