import React from "react";
import "../../components/ServiceSection/Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { faBriefcase, faLaptop } from "@fortawesome/free-solid-svg-icons";
import dataServices from "../../data/services.json";

const Service = ({ activeTab, handleTabClick }) => {
  return (
    <div>
      <section
        className="service-section px-4 md:p-10 lg:px-20 py-4 lg:py-20"
        id="Services"
      >
        <p
          className="our-service"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          OUR SERVICES
        </p>
        <h2
          data-aos="fade-down"
          data-aos-duration="1000"
          className="text-center"
        >
          Bunch of Services{" "}
          <span className="font-normal">to Help Your Business</span>
        </h2>

        <div className="container mx-auto mt-10 text-center ">
          <div className="w-full mx-auto">
            <div
              className="flex flex-col md:flex-row items-center justify-center gap-x-5 xl:gap-x-0"
              data-aos="zoom-in"
              data-aos-duration="500"
            >
              <div
                className={`cursor-pointer px-4 py-2 border-b-2 mr-0 xl:mr-5 ml-0 xl:ml-5 ${
                  activeTab === 1 ? "tab-border-active" : "tab-border"
                }`}
                onClick={() => handleTabClick(1)}
              >
                <div className="flex items-center xl:mr-20 mb-4">
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    className={
                      activeTab === 1 ? "service-icon-active" : "service-icon"
                    }
                  />

                  <p
                    className={`text-left tab-title-active ${
                      activeTab === 1 ? "tab-title-active" : "tab-title"
                    }`}
                  >
                    Business <br />
                    Consulting
                  </p>
                </div>
              </div>
              <div
                className={`cursor-pointer px-4 py-2 border-b-2 mr-0 xl:mr-5 ml-0 xl:ml-5 ${
                  activeTab === 2 ? "tab-border-active" : "tab-border"
                }`}
                onClick={() => handleTabClick(2)}
              >
                <div className="flex items-center xl:mr-20 mb-4">
                  <FontAwesomeIcon
                    icon={faChartBar}
                    className={
                      activeTab === 2 ? "service-icon-active" : "service-icon"
                    }
                  />

                  <p
                    className={`text-left tab-title-active ${
                      activeTab === 2 ? "tab-title-active" : "tab-title"
                    }`}
                  >
                    Manage <br />
                    Operation
                  </p>
                </div>
              </div>
              <div
                className={`cursor-pointer px-4 py-2 border-b-2 mr-0 xl:mr-5 ml-0 xl:ml-5 ${
                  activeTab === 3 ? "tab-border-active" : "tab-border"
                }`}
                onClick={() => handleTabClick(3)}
              >
                <div className="flex items-center xl:mr-20 mb-4">
                  <FontAwesomeIcon
                    icon={faLaptop}
                    className={
                      activeTab === 3 ? "service-icon-active" : "service-icon"
                    }
                  />

                  <p
                    className={`text-left tab-title-active ${
                      activeTab === 3 ? "tab-title-active" : "tab-title"
                    }`}
                  >
                    Software <br />
                    Development
                  </p>
                </div>
              </div>
            </div>

            <div
              className="mt-20 sm:mx-3 "
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              {dataServices.map((service) => (
                <div key={service.tab}>
                  {activeTab === service.tab && (
                    <div className="flex flex-col md:flex-row h-full gap-x-10 mt-10 lg:mt-20 lg:gap-x-16">
                      <div className="flex md:w-1/2 mb-4 md:mb-0  items-center justify-center">
                        <img
                          data-aos="fade-right"
                          data-aos-duration="1000"
                          src={require(`../../assets/Service/${service.image}`)}
                          alt="About Us"
                          className="w-full max-w-full xl:h-[500px] xl:w-auto"
                        />
                      </div>

                      <div
                        className="md:w-1/2 flex flex-col  items-center justify-center"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <p className="service-title mb-5">
                          {service.service}{" "}
                          <span className="font-normal">
                            for Your Business Growth.
                          </span>
                        </p>

                        <p className="service-desc">{service.serviceDesc}</p>
                        <br />
                        <p className="service-desc">{service.serviceDesc1}</p>
                        <br />
                        <p className="service-desc">{service.serviceDesc2}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Service;
