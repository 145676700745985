import React from "react";
import "../../components/WhyUsSection/WhyUs.css";
import WhyUsImage from "../../assets/WhyUs/whyus.png";
import dataClients from "../../data/clients.json";
import dataPatners from "../../data/partners.json";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const WhyUs = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <section className="whyus-section grid grid-cols-12 items-center justify-center w-full mt-[140px]" id="WhyUs">
            <div className="flex flex-col xl:gap-y-8 md:gap-y-4 gap-y-2  col-start-2 col-end-12 md:col-end-6">
                <span>
                    <p
                        data-aos="fade-right"
                        data-aos-duration="800"
                        className="text-[20px] md:text-[22px] xl:text-[39px] font-semibold text-gray-100-main leading-8 md:leading-[35px] lg:leading-[46px]"
                    >
                        Highly Experienced
                    </p>
                </span>
                <p
                    data-aos="fade-up"
                    data-aos-duration="800"
                    className="text-xs lg:text-[16px] font-normal text-[#433E3E] leading-5 lg:leading-[25px] text-justify"
                >
                    Since the establishment of Protonema, we have been able to prove that our performance counts in
                    similar business competition, so that we can gain the trust of our customers.
                </p>
                <span>
                    <p
                        data-aos="fade-right"
                        data-aos-duration="800"
                        className="text-[20px] md:text-[22px] xl:text-[39px] font-semibold text-gray-100-main leading-8 md:leading-[35px] lg:leading-[46px]"
                    >
                        Bunch of Services
                    </p>
                </span>
                <p
                    data-aos="fade-up"
                    data-aos-duration="800"
                    className="text-xs lg:text-[16px] font-normal text-[#433E3E] leading-5 lg:leading-[25px] text-justify"
                >
                    With some of our services, we ensure you can achieve your business goal with our professional
                    services.
                </p>
                <span>
                    <p
                        data-aos="fade-right"
                        data-aos-duration="800"
                        className="text-[20px] md:text-[22px] xl:text-[39px] font-semibold text-gray-100-main leading-8 md:leading-[35px] lg:leading-[46px]"
                    >
                        Quality Support
                    </p>
                </span>
                <p
                    data-aos="fade-up"
                    data-aos-duration="800"
                    className="text-xs lg:text-[16px] font-normal text-[#433E3E] leading-5 lg:leading-[25px] text-justify"
                >
                    With some of our services, we ensure you can achieve your business goal with our professional
                    services.
                </p>
            </div>
            <div className="flex flex-row items-center justify-center w-full col-start-2 col-end-12 md:col-start-9 md:col-end-12">
                <div data-aos="fade-left" className="flex mt-10">
                    <img
                        data-aos="fade-left"
                        data-aos-duration="800"
                        src={WhyUsImage}
                        className="xl:max-w-[536px] lg:max-w-[360px] md:max-w-[300px]"
                        alt=""
                    />
                </div>
            </div>

            {/* Our Clients and Partners */}
            <div className="col-start-2 col-end-12 md:col-start-4 md:col-end-10 flex justify-center items-center text-center mt-[140px]">
                <div>
                    <span>
                        <p className="xl:text-[49px] lg:text-[49px] md:text-[22px] text-[20px] text-[#17A2B8] font-semibold ">
                            Our Clients
                        </p>
                    </span>
                    <p
                        data-aos="fade-up"
                        data-aos-duration="800"
                        className="text-gray-100-main sm:justify-items-center mb-16 xl:text-[25px] "
                    >
                        <span className="font-bold">We take care</span> of our Clients business goal.
                    </p>
                    <div className="flex flex-wrap justify-center">
                        {dataClients.map((client, index) => (
                            <div key={index} className="md:w-1/2 lg:w-1/4 xl:w-1/4 p-4">
                                <img
                                    data-aos="fade-up"
                                    data-aos-duration="800"
                                    src={require(`../../assets/OurClients/${client.Image}`)}
                                    className="rounded-md w-[171px] h-auto"
                                    alt={client.Name}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="col-start-2 col-end-12 md:col-start-4 md:col-end-10 flex justify-center items-center text-center mb-24 mt-[100px]">
                <div>
                    <span>
                        <p className="xl:text-[49px] lg:text-[49px] md:text-[22px] text-[20px] text-[#17A2B8] font-semibold">
                            Our Partner
                        </p>
                    </span>
                    <p
                        data-aos="fade-up"
                        data-aos-duration="800"
                        className="text-gray-100-main sm:justify-items-center mb-16 xl:text-[25px] "
                    >
                        <span className="font-bold">We work</span> with our Partners to deliver the best IT Solution.
                    </p>
                    <div className="flex flex-wrap justify-center">
                        {dataPatners.map((patner, index) => (
                            <div key={index} className="md:w-1/2 lg:w-1/4 xl:w-1/4 p-4">
                                <img
                                    data-aos="fade-up"
                                    data-aos-duration="800"
                                    src={require(`../../assets/OurPartners/${patner.image}`)}
                                    className="rounded-md w-[171px] h-auto"
                                    alt={patner.name}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyUs;
