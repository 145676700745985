import React, { useState, useEffect } from "react";
import NavLogo from "../../assets/Nav/Logo.png";
import "../Navbar/Navbar.css";

function NavMore() {
    const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);
    const [isDesktop, setDesktop] = useState(window.innerWidth > 767);
    const [activeSection, setActiveSection] = useState("");

    const toggleMobileMenu = () => {
        setMobileMenuVisibility(!isMobileMenuVisible);
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const offset = 100;

        const aboutSection = document.getElementById("About");
        const visionSection = document.getElementById("Vision");
        const missionSection = document.getElementById("Mission");

        if (scrollPosition < aboutSection.offsetTop - offset && activeSection !== "Home") {
            setActiveSection("Home");
        } else if (
            scrollPosition >= aboutSection.offsetTop - offset &&
            scrollPosition < visionSection.offsetTop - offset &&
            activeSection !== "About"
        ) {
            setActiveSection("About");
        } else if (
            scrollPosition >= visionSection.offsetTop - offset &&
            scrollPosition < missionSection.offsetTop - offset &&
            activeSection !== "Vision"
        ) {
            setActiveSection("Vision");
        } else if (scrollPosition >= missionSection.offsetTop - offset && activeSection !== "Mission") {
            setActiveSection("Mission");
        }
    };

    useEffect(() => {
        const updateWindowDimensions = () => {
            setDesktop(window.innerWidth > 800);
        };

        window.addEventListener("resize", updateWindowDimensions);
        window.addEventListener("scroll", handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
            window.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <div>
            <div className="navbar sticky lg:px-20   py-2">
                <div className="container mx-auto flex justify-between items-center">
                    <img src={NavLogo} alt="Logo" className="w-40 logo" />
                    <ul className="flex justify-center lg:justify-start mx-8 menu-items text-xl">
                        <li className={` ${activeSection === "Home" ? "active lg:mx-8" : "lg:mx-8"}`}>
                            <a href="#Home">Home</a>
                        </li>
                        <li className={` ${activeSection === "About" ? "active lg:mx-8" : "lg:mx-8"}`}>
                            <a href="#About">About</a>
                        </li>
                        <li className={` ${activeSection === "Vision" ? "active lg:mx-8" : "lg:mx-8"}`}>
                            <a href="#Vision">Vision</a>
                        </li>
                        <li className={` ${activeSection === "Mission" ? "active lg:mx-8" : "lg:mx-8"}`}>
                            <a href="#Mission">Mission</a>
                        </li>
                    </ul>
                    {isDesktop && (
                        <button className="nav-button bg-primary text-white font-bold py-2 rounded-full	ml-10 sm:px-8">
                            <a href="#ContactUs" className="">
                                Contact Us
                            </a>
                        </button>
                    )}
                    <div className="lg:hidden">
                        <button id="mobile-menu-button" className="text-black text-3xl p-4" onClick={toggleMobileMenu}>
                            {isMobileMenuVisible ? "✕" : "☰"}
                        </button>
                    </div>
                </div>
                <div id="mobile-menu" className={`lg:hidden ${isMobileMenuVisible ? "block" : "hidden"}`}>
                    <ul className="block space-y-4 py-8 text-sm	">
                        <li className={`mx-4 ${activeSection === "Home" ? "active" : ""}`}>
                            <a href="#Home" onClick={toggleMobileMenu}>
                                Home
                            </a>
                        </li>
                        <li className={`mx-4 ${activeSection === "About" ? "active" : ""}`}>
                            <a href="#About" onClick={toggleMobileMenu}>
                                About
                            </a>
                        </li>
                        <li className={`mx-4 ${activeSection === "Vision" ? "active" : ""}`}>
                            <a href="#Vision" onClick={toggleMobileMenu}>
                                Vision
                            </a>
                        </li>
                        <li className={`mx-4 ${activeSection === "Mission" ? "active" : ""}`}>
                            <a href="#Mission" onClick={toggleMobileMenu}>
                                Mission
                            </a>
                        </li>
                        <li>
                            <button
                                className="nav-button-2 text-white font-bold py-2 px-4 rounded-full mt-8"
                                onClick={toggleMobileMenu}
                            >
                                <a href="#ContactUs">Contact Us</a>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default NavMore;
