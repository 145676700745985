import "../assets/css/privacy.css";
import FooterKhidma from "../components/Footer/FooterKhidma.jsx";
import NavbarKhidma from "../components/NavbarKhidma/NavbarKhidma.jsx";
import OurContactKhidma from "../components/OurContact/OurContactKhidma.jsx";
import { useEffect } from "react";

const KhidmaPrivacy = () => {
    useEffect(() => {
        document.title = "Kebijakan Privasi";
    });
    return (
        <>
            <NavbarKhidma />
            <div className="flex flex-col mx-auto max-w-6xl px-4 md:px-12 py-12 gap-y-20 font-montserrat">
                <div className="flex flex-col gap-y-3">
                    <h1 className="text-xl md:text-[25px] xl:text-[39px] text-[#433E3E]  font-bold">
                        Kebijakan Privasi
                    </h1>
                    <div className="flex flex-col gap-y-5 text-[#595454]">
                        <p className="text-sm md:text-[18px] xl:text-xl ">Terakhir di perbaharui: 24 April 2024</p>
                        <p className="text-sm md:text-[18px] xl:text-xl leading-6 md:leading-7 xl:leading-8	">
                            Kami berkomitmen untuk melindungi dan menghormati privasi pengguna. Kebijkan Privasi ini
                            menjelaskan cara kami mengumpulkan, menggunakan, membagikan. Jika pengguna tidak setuju
                            dengan kebijakan ini, maka sebaiknya pengguna tidak menggunaka platform ini.
                        </p>
                    </div>
                </div>

                <div>
                    <ol className="list-decimal list-inside text-lg md:text-[25px] xl:text-[31px] font-semibold text-[#433E3E]">
                        <li className="mb-20">
                            <span className="">Informasi yang dikumpulkan</span>
                            <div className="flex flex-col gap-y-5 text-[#595454]">
                                <p className="mt-5 text-sm md:text-[18px] xl:text-xl  font-normal">
                                    Untuk menggunakan produk dan layanan kami, pengguna harus memiliki akun dan membuat
                                    akun.
                                </p>
                                <ol className="list-decimal list-outside text-sm md:text-[18px] xl:text-xl  font-semibold">
                                    <li>
                                        <span className="font-normal">Data Pribadi</span>

                                        <p className="font-normal leading-6 md:leading-7 xl:leading-8">
                                            Data pengguna yang dapat diakses saat pengguna mendaftarkan akun yang berisi
                                            nama, email, kata sandi, nomor telepon, alamat tagihan, rekening atau
                                            informasi pembayaran, informasi yang pengguna pengguna kirim atau terhubung
                                            dengan perangkat yang digunakan untuk mengakses platform kami. Informasi
                                            tentang jaringan pengguna, foto atau video yang diambil untuk menyerahkan
                                            bukti transaksi.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </li>
                        <li>
                            <span>Informasi Pembayaran</span>
                            <p className="mt-5 mb-5 text-sm md:text-[18px] xl:text-xl  font-normal text-[#595454] leading-6 md:leading-7 xl:leading-8	">
                                Saat melakukan transaksi pembayaran melalui platform, kami mengumpulkan informasi
                                tentang transaksi pembayaran seperti nomor rekening, tagihan, pengiriman.
                            </p>

                            <ul className="list-disc list-outside pl-4 text-sm md:text-[18px] xl:text-xl  font-normal text-[#595454] leading-6 md:leading-7 xl:leading-8">
                                <li>
                                    Dengan menggunakan layanan mendaftarkan akun dan mengakses layanan, pengguna
                                    menyetujui dan mengakui bahwa menerima persyaratan atau Kebijakan Privasi ini.
                                    Apabila pengguna tidak mengizinkan pengelolaan data pribadi, mohon jangan
                                    menggunakan layanan atau mengakses platform kami.
                                </li>
                                <li>
                                    Kami akan mengumpulkan data saat pengguna mendaftar atau menggunakan layanan
                                    platform, Kami menggunakan informasi pengguna untuk meningkatkan, mendukung dan
                                    mngelola platform dan mengizinkan pengguna untuk menggunakan fungsi dan layanan,
                                    Saat pengguna memberikan izin pada perangkat pengguna untuk berbagi informasi dengan
                                    platform kami. Saat pengguna melakukan transaksi pembayaran. Untuk memenuhi
                                    permintaan produk, layanan, informasi, menyediakan fitur belanja dan memfasilitasi
                                    pembelian dan pengiriman produk, barang dan layanan.
                                </li>
                                <li>
                                    Durasi menyimpan informasi, kami penyimpan informasi pengguna selama pengguna
                                    terdaftar, Kami mungkin menyimpan informasi tertentu lebih lama dari yang ditentukan
                                    dalam kebijakan untuk mematuhi peraturan hukum dan untuk alasan keamanan.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>

                <OurContactKhidma />
            </div>
            <FooterKhidma />
        </>
    );
};

export default KhidmaPrivacy;
