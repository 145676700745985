import React from "react";
import AboutKhidmaPng from "../../assets/About/AboutKhidma.png";
import DownloadAppStore from "../../assets/About/DownloadAppStore.png";
import DownloadGooglePlay from "../../assets/About/DownloadGooglePlay.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const AboutKhidma = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <section className="flex flex-row gap-x-9 mx-auto max-w-6xl px-4 md:px-12 py-20 font-montserrat">
            <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
                <div data-aos="fade-right" data-aos-duration="1000" data-delay="10">
                    <img src={AboutKhidmaPng} alt="Tentang Khidma" className="rounded-lg shadow-lg w-96" />
                </div>
                <div className="gap-x-9 text-[#433E3E]">
                    <h2
                        className=" text-[28px]  lg:text-[39px] mb-4 font-bold"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-delay="10"
                    >
                        Tentang Khidma
                    </h2>
                    <p
                        className="text-gray-700  text-sm  lg:text-[20px] leading-relaxed"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-delay="10"
                    >
                        Assalamualaikum, selamat datang di Khidma senang dapat menyapa Anda melalui halaman ini! Terima
                        kasih sudah menggunakan aplikasi Khidma untuk memenuhi segala kebutuhan haji/umroh Anda. Khidma
                        adalah pelayanan penyedia jasa kebutuhan Jemaah Haji selama berada di Tanah Suci Makkah. Dengan
                        fitur-fitur inovatif, Khidma memastikan kebutuhan Anda di Tanah Suci menjadi terpenuhi.
                    </p>
                    <div className="flex gap-4 mt-10" data-aos="fade-up" data-aos-duration="1000" data-delay="10">
                        <a href="https://apps.apple.com/app/6479632077">
                            <img src={DownloadAppStore} alt="Download di App Store" className="w-40" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=co.id.protonema.khidma">
                            <img src={DownloadGooglePlay} alt="Download di Google Play" className="w-40" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutKhidma;
