import React from "react";
import NotFoundImage from "../assets/404/404.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const NotFound = () => {
    useEffect(() => {
        document.title = "404 Not Found";
    });
    return (
        <div className="flex items-center justify-center h-screen">
            <section className="notfound text-center">
                <img src={NotFoundImage} className="w-full max-w-md mx-auto" alt="404 Not Found" />
                <p className="text-gray-500 mt-2">we are sorry :( the page you requested cannot be found.</p>
                <button className="mt-5 bg-transparent  border-2 border-[#17A2B8] text-[#17A2B8] py-2 px-5 rounded-full hover:bg-[#17A2B8] hover:text-white transition duration-300">
                    <Link to="/">Back to Home</Link>
                </button>
            </section>
        </div>
    );
};

export default NotFound;
