import App from "./App";
import More from "./pages/More";
import NotFound from "./pages/NotFound";
import KhidmaPrivacy from "./pages/KhidmaPrivacy";
import KhidmaTermsConditions from "./pages/KhidmaTermsConditions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import KhidmaAbout from "./pages/KhidmaAbout";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/more" element={<More />} />
                <Route path="/khidma/kebijakan-privasi" element={<KhidmaPrivacy />} />
                <Route path="/khidma/" element={<KhidmaAbout />} />
                <Route path="/khidma/syarat-dan-ketentuan" element={<KhidmaTermsConditions />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
