import React, { useState } from "react";
import MissionImage from "../../assets/Mission/Mission.png";
import "../Mission/Mission.css";
import dataMission from "../../data/mission.json";

const Mission = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <section
      id="Mission"
      className="mission flex flex-col max-w-6xl mx-auto lg:justify-between md:flex-row items-center gap-x-10 lg:gap-x-32 mb-32 px-4 md:px-12 mt-32 scroll-mt-24"
    >
      <div
        className="lg:max-w-[424px]"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <img src={MissionImage} alt="" />
      </div>

      <div className="flex flex-col justify-start gap-y-6 ">
        <p className="title" data-aos="fade-up" data-aos-duration="1000">
          Mission
        </p>

        <div className="mx-auto" data-aos="fade-left" data-aos-duration="1000">
          {dataMission.map((mission) => (
            <div className="mb-5" key={mission.index}>
              <button
                className={`w-full my-3 lg:my-5 text-left ${
                  activeIndex === mission.index
                    ? "title-accordion-active"
                    : "title-accordion"
                }`}
                onClick={() => toggleAccordion(mission.index)}
              >
                {mission.title}
              </button>
              <hr className="bg-[#878484] max-w-full" />
              <div
                className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                  activeIndex === mission.index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="my-3 lg:my-5 desc-accordion">
                  {mission.desc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Mission;
