import React from "react";
import "aos/dist/aos.css";
import NavbarKhidma from "../components/NavbarKhidma/NavbarKhidma";
import OurContactKhidma from "../components/OurContact/OurContactKhidma";
import FooterKhidma from "../components/Footer/FooterKhidma";
import { useEffect } from "react";

const KhidmaTermsConditions = () => {
    useEffect(() => {
        document.title = "Syarat dan Ketentuan";
    });
    return (
        <>
            <NavbarKhidma />
            <div className="flex flex-col mx-auto max-w-6xl px-4 md:px-12 py-12 text-[#433E3E] text-sm md:text-base lg:text-base xl:text-base">
                <h1 className="lg:text-[40px] md:text-3xl text-2xl font-bold mb-5">Syarat & Ketentuan</h1>
                <div>
                    <p className="mb-4">
                        Dengan mengunduh, memasang, dan/atau menggunakan Aplikasi Khidma, Anda menyetujui bahwa Anda
                        telah membaca, memahami, dan menyetujui Syarat dan Ketentuan (“Syarat dan Ketentuan”) ini.
                        Syarat dan Ketentuan ini menandakan perjanjian hukum antara Anda dan Khidma dan berlaku untuk
                        kunjungan Anda ke dan penggunaan Anda atas Platform dan Layanan kami (sesuai yang didefinisikan
                        di bawah ini). Namun, harap diperhatikan bahwa syarat, batasan, dan kebijakan privasi tambahan
                        dapat berlaku.
                    </p>
                    <p className="mb-10">
                        Apabila Anda tidak setuju terhadap salah satu, sebagian, atau seluruh isi yang tertuang dalam
                        Ketentuan Penggunaan dan Kebijakan Privasi ini, silakan untuk menghapus Platform dalam perangkat
                        elektronik Anda dan/atau tidak mengakses Platform dan/atau tidak menggunakan Layanan Kami. Mohon
                        untuk dapat diperhatikan pula bahwa Ketentuan Penggunaan dan Kebijakan Privasi dapat diperbarui
                        dari waktu ke waktu.
                    </p>
                    <ol className="list-decimal list-inside lg:text-[32px] md:text-2xl text-xl font-semibold">
                        <li className="mb-8">
                            <span className="lg:text-[32px] md:text-2xl text-xl font-semibold">Ketentuan Umum</span>
                            <ol className="list-decimal list-inside pl-4 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                <li>
                                    Semua referensi di bawah Syarat dan Ketentuan ini untuk “Khidma” atau “kami” merujuk
                                    ke PT. ….. dan badan-badan yang terkait (“Khidma”), dan semua referensi untuk
                                    “Pelanggan” atau “Anda” merujuk ke pengguna Platform dan/atau Layanan yang
                                    berwenang.
                                </li>
                                <li>
                                    Platform adalah aplikasi (versi Android atau iOS) yang dikelola oleh Kami
                                    sebagaimana diperbarui dari waktu ke waktu.
                                </li>
                                <li>
                                    Platform berfungsi sebagai sarana untuk anda memesan DAM, memesan makanan (katering)
                                    dan menukar mata uang (Valas).
                                </li>
                                <li>
                                    Koneksi internet diperlukan untuk dapat menggunakan ayanan dan biaya terkait
                                    penggunaan koneksi internet tersebut ditanggung sepenuhnya oleh Anda.
                                </li>
                                <li>
                                    Jenis layanan yang dapat digunakan melalui Platform adalah:
                                    <ol className="list-none pl-7">
                                        <li>Memesan DAM, Katering dan Valas.</li>
                                        <li>
                                            Layanan lain yang dapat kami tambahkan dari waktu ke waktu, (masing-masing
                                            disebut “Layanan” dan secara kolektif disebut “Layanan-Layanan”).
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Kami dapat menggunakan jasa pihak ketiga terkait penyediaan layanan pembayaran.
                                    Apabila terjadi kegagalan pada sistem pembayaran, Kami akan berupaya semaksimal
                                    mungkin dalam membantu menyelesaikan masalah yang mungkin timbul. Penyedia jasa
                                    perbankan/pembayaran yang dipilih oleh Anda dapat mengenakan biaya tambahan kepada
                                    Anda atas layanan yang diberikan.
                                </li>
                                <li>
                                    Platform memungkinkan Anda untuk mengirimkan permintaan atas suatu Layanan kepada
                                    Penyedia Layanan. Penyedia Layanan memiliki kebijakan sendiri dan kewenangan penuh
                                    untuk menerima atau menolak setiap permintaan Anda atas Layanan. Jika Penyedia
                                    Layanan menerima permintaan Anda.
                                </li>
                                <li>
                                    Setiap fitur atau fasilitas dalam Platform dapat diperbarui atau diubah sesuai
                                    dengan kebutuhan dan perkembangan Platform.
                                </li>
                                <li>
                                    Anda dapat memberikan informasi mengenai data pribadi dan riwayat pemesanan dan kami
                                    akan menyimpan serta menampilkannya dalam akun Anda. Kerahasiaan data Anda terjamin
                                    dan akan digunakan oleh Kami dan/atau Penyedia Layanan untuk keperluan interaksi
                                    dengan operator dan/atau keperluan lainnya yang dilakukan di dalam Platform yang
                                    telah Anda setujui sesuai dengan ketentuan perundang-undangan yang berlaku dan
                                    Kebijakan Privasi.
                                </li>
                                <li>
                                    Dengan menggunakan Platform, Anda memahami dan menyetujui bahwa percakapan melalui
                                    fitur chat akan tersimpan secara otomatis, diarsipkan dan dihentikan untuk keperluan
                                    legal dan peningkatan kualitas layanan. Kerahasiaan percakapan Anda terjamin dan
                                    informasi tidak akan disebarluaskan.
                                </li>
                                <li>
                                    Kami dapat menghentikan atau membatasi proses registrasi atau penggunaan Platform
                                    oleh Anda jika ditemukan pelanggaran dari Ketentuan Penggunaan ini atau peraturan
                                    perundang-undangan yang berlaku.
                                </li>
                            </ol>
                        </li>
                        <li className="mb-8 ">
                            <span className="lg:text-[32px] md:text-2xl text-xl font-semibold">
                                Akses Platform dan Layanan
                            </span>
                            <p className="mb-4 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                Khidma memberikan izin terbatas yang dapat dicabut kembali kepada anda untuk mengakses
                                Layanan dengan tunduk pada syarat dan ketentuan Syarat Layanan ini. Semua Konten
                                eksklusif, merek dagang, merek layanan, nama merek, logo dan kekayaan intelektual
                                lainnya (“Kekayaan Intelektual”) yang ditampilkan merupakan milik Khidma dan bila
                                berlaku, pemilik pihak ketiga yang diidentifikasi. Tidak ada hak atau izin yang
                                diberikan secara langsung atau tidak langsung kepada pihak mana pun yang mengakses
                                Platform untuk menggunakan atau memperbanyak Kekayaan Intelektual, dan tidak ada pihak
                                yang mengakses Platform yang dapat mengklaim hak, kepemilikan atau kepentingan apapun di
                                dalamnya. Dengan menggunakan atau mengakses Layanan anda setuju untuk mematuhi hak
                                cipta, merek dagang, merek layanan, dan semua undang-undang yang berlaku lainnya yang
                                melindungi Layanan, Platform dan Kontennya.
                            </p>
                            <p className="mb-2 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                Anda setuju untuk tidak menyalin, mendistribusikan, mempublikasikan ulang, mengirimkan,
                                menampilkan secara terbuka, melakukan secara terbuka, mengubah, menyesuaikan, menyewa,
                                menjual, atau membuat karya turunan dari bagian apapun dari Layanan, Situs atau
                                Kontennya. Anda juga tidak diperbolehkan, tanpa persetujuan tertulis sebelumnya dari
                                kami, menduplikasi atau membingkai bagian apapun atau seluruh konten Situs ini di server
                                lain mana pun atau sebagai bagian dari situs web lain apapun. Selain itu, anda setuju
                                bahwa anda tidak akan menggunakan robot, spider atau perangkat otomatis maupun proses
                                manual lain apapun untuk memantau atau menyalin Konten kami, tanpa persetujuan tertulis
                                sebelumnya dari kami.
                            </p>
                            <ol className="list-decimal list-inside pl-4 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                <li>
                                    Anda setuju untuk:
                                    <ul class="pl-4">
                                        <li>
                                            &#8211; Mengakses Platform dan/atau menggunakan Layanan hanya untuk tujuan
                                            yang sah dan dengan cara yang sah, setiap saat, dan juga setuju untuk
                                            melakukan aktivitas apapun terkait Layanan atau Platform dengan itikad baik
                                        </li>
                                        <li>
                                            &#8211; Mematuhi semua dan panduan, pemberitahuan, peraturan operasional,
                                            kebijakan, dan instruksi apa pun terkait penggunaan Layanan dan/atau akses
                                            ke Platform, sekaligus amendemen apa pun yang diterbitkan oleh kami dari
                                            waktu ke waktu
                                        </li>
                                        <li>
                                            &#8211; Menjamin agar informasi atau data apa pun yang Anda publikasikan
                                            atau tampilkan di Platform terkait Layanan bersifat akurat.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Akses ke area-area Platform yang terlindungi oleh kata sandi dan penggunaan Layanan
                                    terbatas hanya untuk Pelanggan dengan akun Khidma. Anda tidak dapat memperoleh atau
                                    berusaha memperoleh akses yang tidak sah ke bagian-bagian Platform dan/atau Layanan
                                    ini maupun ke informasi lain yang terlindungi di Platform, melalui cara apa pun yang
                                    secara sengaja tidak kami sediakan untuk penggunaan Anda.
                                </li>
                                <li>
                                    Dengan mengakses Platform dan/atau menggunakan Layanan, Anda menyatakan dan menjamin
                                    bahwa Anda adalah individu yang secara hukum berhak untuk memasuki perjanjian yang
                                    mengikat di bawah hukum Republik Indonesia, dalam Syarat dan Ketentuan berikut ini,
                                    dan bahwa Anda berusia minimal 21 tahun atau sudah menikah dan tidak sedang di bawah
                                    perwalian. Jika Anda berusia di bawah 21 tahun dan tidak menikah, Anda menyatakan
                                    dan menjamin bahwa Anda telah memperoleh izin dari orang tua atau wali hukum Anda,
                                    kecuali Anda menyatakan sebaliknya. Dengan memberikan persetujuan, orang tua atau
                                    wali hukum Anda setuju untuk bertanggung jawab atas:
                                    <ul>
                                        <li>
                                            &#8211; Semua tindakan Anda terkait akses ke dan penggunaan Platform
                                            dan/atau Layanan
                                        </li>
                                        <li>&#8211; Biaya apapun terkait penggunaan Anda atas Layanan apa pun</li>
                                        <li>
                                            &#8211; Kepatuhan Anda terhadap Syarat dan Ketentuan ini. Jika Anda tidak
                                            memperoleh izin dari orang tua atau wali hukum, Anda harus berhenti
                                            mengakses Platform dan/atau menggunakan Layanan.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Anda menyatakan akan menggunakan Platform ini hanya untuk tujuan yang dimaksudkan,
                                    yaitu untuk memperoleh Layanan.
                                </li>
                                <li>
                                    Anda akan merahasiakan dan tidak akan menyalahgunakan informasi yang Anda terima
                                    dari penggunaan Platform. Anda akan memperlakukan Penyedia Layanan dengan hormat dan
                                    tidak akan melakukan perilaku atau aktivitas tidak sah, mengancam, atau melecehkan
                                    saat menggunakan Layanan.
                                </li>
                                <li>
                                    Anda memahami dan setuju bahwa penggunaan Anda atas Platform juga harus mematuhi
                                    Kebijakan Privasi kami, seperti yang mungkin diamandemen dari waktu ke waktu.
                                </li>
                                <li>
                                    Dengan memberikan informasi kepada kami, Anda menyatakan bahwa Anda berhak untuk
                                    memberikan informasi kepada kami untuk digunakan dan dibagikan dengan Penyedia
                                    Layanan dan bahwa informasi tersebut akurat, terkini, dan lengkap.
                                </li>
                                <li>
                                    Terkait penggunaan Anda atas Platform atau Layanan, atau selama interaksi Anda
                                    dengan Khidma, Pelanggan lain, Penyedia Layanan, atau pihak ketiga lain apa pun
                                    melalui Platform, Anda dilarang:
                                    <ul>
                                        <li>
                                            &#8211; Melanggar Syarat dan Ketentuan, Kebijakan Privasi, atau kebijakan
                                            lain apa pun yang ada di Platform ini
                                        </li>
                                        <li>&#8211; Melanggar hukum, peraturan, atau hak pihak ketiga mana pun</li>
                                        <li>
                                            &#8211; Melanggar hak cipta, paten, merek dagang, rahasia dagang, hak
                                            kekayaan intelektual apa pun, hak publisitas atau privasi dari Khidma atau
                                            pihak ketiga mana pun
                                        </li>
                                        <li>
                                            &#8211; Melakukan tindakan yang mencemarkan, memfitnah, mengancam, atau
                                            melecehkan
                                        </li>
                                        <li>
                                            &#8211; Memberikan informasi yang salah, palsu, tidak akurat, atau
                                            menyesatka
                                        </li>
                                        <li>
                                            &#8211; Menolak secara tidak wajar untuk bekerja sama dalam penyelidikan
                                            yang dilakukan oleh khidma atau memberikan konfirmasi mengenai identitas
                                            Anda atau informasi lain yang diminta oleh Khidma
                                        </li>
                                        <li>
                                            &#8211; Mengontrol akun pelanggan yang terhubung dengan cara apapun ke akun
                                            lain yang telah atau diduga melakukan aktivitas apa pun yang dilarang di
                                            bawah Syarat dan Ketentuan ini
                                        </li>
                                        <li>
                                            &#8211; Menggunakan Platform sebagai sarana untuk menyebarkan iklan dan
                                            materi lain apa pun yang tidak diinginkan kepada pihak ketiga, mengirimkan
                                            surat berantai atau skema piramida, atau memublikasikan konten yang sama
                                            lebih dari sekali (spam)
                                        </li>
                                        <li>
                                            &#8211; Menggunakan Platform, akun Anda, atau Layanan dengan cara yang
                                            mengakibatkan atau dapat mengakibatkan keluhan, perselisihan, tuntutan,
                                            denda, penalti, dan tanggung jawab lain kepada Khidma, Pelanggan lain,
                                            Penyedia Layanan, atau pihak ketiga
                                        </li>
                                        <li>
                                            &#8211; Mengambil tindakan apa pun yang memaksakan beban berat yang tidak
                                            wajar atau tidak proporsional ke infrastruktur kami atau tindakan lain yang
                                            dapat mengakibatkan risiko atau masalah material untuk infrastruktur kami
                                        </li>
                                        <li>
                                            &#8211; Menyebarkan virus, trojan horse, worm, atau teknologi pemrograman
                                            komputer lain yang dapat membahayakan Platform, kepentingan, atau properti
                                            apa pun dari Pelanggan lain, Penyedia Layanan, dan pihak ketiga mana pun,
                                            termasuk pengguna lain di Platform dan Layanan
                                        </li>
                                        <li>
                                            &#8211; Berusaha memperoleh akses yang tidak sah ke atau mengganggu atau
                                            menghalangi sistem atau jaringan komputer lain yang terhubung ke Platform
                                            atau Layanan
                                        </li>
                                        <li>
                                            &#8211; Menyebarkan atau mempromosikan materi tidak senonoh, pornografi,
                                            atau menyinggung atau materi lain yang bertentangan dengan kebijakan publik
                                            dan/atau hukum atau peraturan apapun yang berlaku
                                        </li>
                                        <li>
                                            &#8211; Akibat tindakan atau kelalaian Anda, melakukan apa pun yang dapat
                                            menyebabkan kami kehilangan layanan apa pun dari penyedia layanan internet,
                                            pemroses pembayaran, atau penyedia kami lainnya
                                        </li>
                                        <li>
                                            &#8211; Mengganggu penggunaan dan kenyamanan pengguna lain atas Platform
                                            atau Layanan
                                        </li>
                                        <li>
                                            &#8211; Memfasilitasi aktivitas ilegal, tidak sah, atau penipuan berbentuk
                                            apa pun
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Apabila kami memiliki alasan untuk menduga bahwa Anda terlibat dalam aktivitas apa
                                    pun yang dilarang, sesuai yang ditetapkan dalam klausa 2.8 di atas, kami dapat,
                                    tanpa tanggung jawab kepada Anda, mengambil tindakan berikut:
                                    <ul>
                                        <li>
                                            &#8211; Melaporkan aktivitas mencurigakan atau ilegal apa pun ke otoritas
                                            yang relevan
                                        </li>
                                        <li>&#8211; Membatalkan atau menolak transaksi apa pun</li>
                                        <li>&#8211; Menangguhkan atau menutup akun Pelanggan Anda</li>
                                        <li>
                                            &#8211; Menerapkan, sesuai kebijaksanaan tunggal kami, prosedur pencegahan
                                            dan deteksi serta menolak pelaksanaan transaksi apabila ada dasar untuk
                                            menduga bahwa akun Pelanggan digunakan atau mungkin digunakan untuk tujuan
                                            yang ilegal, tidak sah, atau penipuan
                                        </li>
                                        <li>
                                            &#8211; Mengambil langkah lebih lanjut sesuai yang dianggap perlu oleh
                                            Khidma, sesuai kebijaksanaan tunggal kami, termasuk mengambil langkah hukum
                                            terhadap Anda
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Apabila kami menutup akun Pelanggan Anda, kami akan memberikan pemberitahuan
                                    penutupan secara tertulis segera setelah dapat dilakukan.
                                </li>
                                <li>
                                    Apabila terjadi penangguhan akun Pelanggan Anda, kami akan menginformasikan
                                    penangguhan kepada Anda, ketika memungkinkan, dan memberikan Anda kesempatan untuk
                                    meminta pemulihan akses jika dan saat sesuai. Pemulihan akses apapun merupakan
                                    kebijaksanaan Khidma dan harus mematuhi kebijakan dan prosedur Khidma sekaligus
                                    hukum yang berlaku.
                                </li>
                            </ol>
                        </li>
                        <li className="mb-8">
                            <span className="lg:text-[32px] md:text-2xl text-xl font-semibold">Tanpa Jaminan</span>
                            <ol className="list-decimal list-inside pl-4 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                <li>
                                    Kami tidak memberikan pernyataan, jaminan, atau garansi atas keandalan, ketepatan
                                    waktu, kualitas, kesesuaian, ketersediaan, keakuratan, atau kelengkapan Layanan
                                    dan/atau Platform, termasuk tetapi tidak terbatas untuk, Layanan yang diperoleh oleh
                                    atau dari Penyedia Layanan melalui penggunaan Platform.
                                </li>
                                <li>
                                    Sejauh yang diizinkan oleh hukum yang berlaku, kami tidak memberikan pernyataan atau
                                    menjamin bahwa:
                                    <ul>
                                        <li>
                                            &#8211; Penggunaan Layanan dan/atau Platform akan aman, tepat waktu, tidak
                                            terganggu, atau bebas dari kesalahan, atau beroperasi dengan baik dengan
                                            perangkat keras, perangkat lunak, sistem, atau data apa pun
                                        </li>
                                        <li>&#8211; Layanan akan memenuhi persyaratan atau harapan Anda</li>
                                        <li>&#8211; Data apa pun yang disimpan bersifat akurat atau handal</li>
                                        <li>
                                            &#8211; Kualitas layanan, informasi, atau materi lain apa pun yang Anda
                                            peroleh melalui Platform akan memenuhi persyaratan atau harapan Anda
                                        </li>
                                        <li>&#8211; Kesalahan atau kecacatan di Platform akan diperbaiki</li>
                                        <li>
                                            &#8211; Aplikasi atau server yang menyusun Platform bebas dari virus atau
                                            komponen berbahaya lainnya
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Layanan-layanan diberikan kepada Anda sebagaimana adanya. Sejauh yang diizinkan oleh
                                    hukum yang berlaku, semua ketentuan, pernyataan, dan jaminan, baik tersirat maupun
                                    tersurat, sah atau tidak, termasuk tetapi tidak terbatas pada, jaminan pemberian
                                    layanan tersirat, keselarasan untuk tujuan khusus, atau ketiadaan pelanggaran atas
                                    hak pihak ketiga, dengan ini dikecualikan dan disangkal hingga batas tertinggi dan
                                    maksimal. Anda menyatakan dan menyetujui bahwa seluruh risiko yang timbul akibat
                                    penggunaan Anda atas Platform dan Layanan tetap dan mutlak merupakan tanggung jawab
                                    Anda, dan Anda tidak akan meminta jaminan apa pun kepada Khidma.
                                </li>
                            </ol>
                        </li>
                        <li className="mb-8">
                            <span className="lg:text-[32px] md:text-2xl text-xl font-semibold">
                                Pengecualian Tanggung Jawab
                            </span>
                            <ol className="list-decimal list-inside pl-4 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                <li>
                                    Kami mengambil langkah-langkah teknis dan keamanan yang sesuai dan wajar untuk
                                    menjaga agar Platform tetap aman dan bebas dari virus dan kesalahan. Namun kami
                                    tidak dapat menjamin keamanan basis data kami maupun menjamin bahwa informasi yang
                                    Anda berikan tidak akan dihadang saat dikirimkan kepada kami. Kami akan
                                    menginformasikan kepada Anda secara tertulis apabila terdapat pelanggaran keamanan
                                    data yang melibatkan informasi pribadi Anda.
                                </li>
                                <li>
                                    Platform dapat mengalami pembatasan, penundaan, dan masalah lain terkait penggunaan
                                    internet dan komunikasi elektronik, termasuk kecacatan perangkat yang digunakan oleh
                                    Anda atau Penyedia Layanan, tidak terhubung, di luar jangkauan, tidak aktif, atau
                                    tidak berfungsi. Kami tidak bertanggung jawab atas penundaan, kegagalan pengiriman,
                                    kerusakan, atau kerugian apa pun akibat masalah-masalah tersebut.
                                </li>
                                <li>
                                    Khidma dan/atau pemberi lisensi, dan masing-masing anggota, pejabat, direktur,
                                    komisaris, karyawan, pengacara, dan afiliasi nya (“Penerima Ganti Rugi”) tidak akan
                                    bertanggung jawab kepada Anda atas kerugian, biaya, pengeluaran (termasuk biaya dan
                                    pengeluaran hukum), kerusakan, penalti, atau jumlah pembayaran apa pun atau
                                    bagaimana pun yang disebabkan (apa pun bentuk tindakannya), secara langsung atau
                                    tidak langsung, terkait:
                                    <ul>
                                        <li>
                                            &#8211; Akses, penggunaan, dan/atau ketidakmampuan apa pun untuk menggunakan
                                            Platform atau Layanan
                                        </li>
                                        <li>
                                            &#8211; Ketergantungan terhadap data atau informasi apa pun yang tersedia
                                            melalui Platform dan/atau Layanan
                                        </li>
                                        <li>
                                            &#8211; Kegagalan, kesalahan, kelalaian, gangguan, penundaan sistem, server,
                                            atau koneksi apa pun dalam pengiriman, virus komputer atau kode, program
                                            agen atau macros yang berbahaya, menghancurkan, atau merusak lainnya
                                        </li>
                                        <li>
                                            &#8211; Penggunaan apa pun atas atau akses ke situs web atau halaman web
                                            lain apa pun yang tertaut ke Platform
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li className="mb-8">
                            <span className="lg:text-[32px] md:text-2xl text-xl font-semibold">Ganti Rugi</span>
                            <ol className="list-decimal list-inside pl-4 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                <li>
                                    Anda setuju untuk melindungi, memberikan ganti rugi, dan membebaskan kami dan pihak
                                    mana pun yang menerima ganti rugi dari dan terhadap semua dan tuntutan, biaya,
                                    kerusakan, kerugian, tanggung jawab, dan pengeluaran (termasuk biaya pengacara) apa
                                    pun yang timbul akibat atau terkait:
                                    <ul>
                                        <li>
                                            (i). Penggunaan Anda atas Layanan dan/atau Platform, transaksi Anda dengan
                                            Penyedia Layanan, penyedia pihak ketiga;
                                        </li>
                                        <li>
                                            (ii). Pelanggaran Anda atas ketentuan apa pun dalam Syarat dan Ketentuan ini
                                            atau hukum atau peraturan apa pun yang berlaku, baik yang dirujuk di sini
                                            maupun tidak;
                                        </li>
                                        <li>
                                            (iii). Pelanggaran Anda atas hak pihak ketiga apa pun, termasuk Penyedia
                                            Layanan yang menyediakan Layanan melalui Platform; atau
                                        </li>
                                        <li>(iv). Penggunaan atau penyalahgunaan Anda atas Platform</li>
                                    </ul>
                                </li>
                                <li>
                                    Kewajiban perlindungan dan ganti rugi ini akan tetap berlaku setelah Syarat dan
                                    Ketentuan serta penggunaan Anda atas Platform dan/atau Layanan berakhir.
                                </li>
                            </ol>
                        </li>
                        <li className="mb-8">
                            <span className="lg:text-[32px] md:text-2xl text-xl font-semibold">Penutup</span>
                            <ol className="list-decimal list-inside pl-4 text-sm md:text-base lg:text-base xl:text-base font-normal mt-4">
                                <li>
                                    Anda tidak berkewajiban untuk menggunakan Platform dan/atau Layanan dan dapat
                                    memilih untuk berhenti menggunakannya kapan pun dengan membatalkan akun Anda dan
                                    berhenti mengakses Platform, sehingga menonaktifkan penggunaan Anda atas Layanan.
                                </li>
                                <li>
                                    Anda memahami bahwa setelah akun Anda dihapus maka akun Anda (termasuk seluruh
                                    riwayat transaksi, catatan medis, dan seluruh layanan di dalamnya) akan terhapus
                                    selamanya dan tidak dapat dipulihkan kembali karena alasan apa pun.
                                </li>
                                <li>
                                    Ketentuan Penggunaan ini diatur dan ditafsirkan serta dilaksanakan berdasarkan hukum
                                    yang berlaku di Negara Republik Indonesia dan Anda dengan tegas menyetujui bahwa
                                    bahwa ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata dan ketentuan lainnya
                                    yang mewajibkan adanya pengesahan atau persetujuan pengadilan untuk dapat mengakhiri
                                    Ketentuan Penggunaan tidak berlaku terhadap Ketentuan Penggunaan ini.
                                </li>
                                <li>
                                    Segala sengketa yang berkaitan dengan Ketentuan Penggunaan ini, diselesaikan secara
                                    musyawarah untuk mufakat atau melalui Badan Arbitrase Nasional Indonesia (BANI),
                                    sesuai dengan prosedur yang berlaku di BANI. Apabila kedua belah pihak tidak sepakat
                                    untuk menyelesaikannya sengketa di BANI, maka sengketa akan diselesaikan melalui
                                    Pengadilan Negeri Jakarta Selatan.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="flex flex-col mx-auto max-w-6xl px-4 md:px-12">
                <OurContactKhidma />
            </div>
            <FooterKhidma />
        </>
    );
};

export default KhidmaTermsConditions;
