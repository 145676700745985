import React, { useState } from "react";
import NavMore from "../components/Navbar/NavMore";
import HeroMore from "../components/HeroSection/HeroMore";
import AboutMore from "../components/AboutUs/AboutMore";
import Vision from "../components/Vision/Vision";
import Mission from "../components/Mission/Mission";
import OurContact from "../components/OurContact/OurContact";
import Footer from "../components/Footer/Footer";

import "../App.css";

function More() {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div className="App">
            <NavMore />
            <HeroMore />
            <AboutMore />

            {/* Conditional rendering for Service component */}

            <Vision />
            <Mission />
            <OurContact />
            <Footer activeTab={activeTab} handleTabClick={handleTabClick} />
        </div>
    );
}
export default More;
