import React from "react";
import AboutImage from "../../assets/About/About.png";

const AboutMore = () => {
    return (
        <section
            id="About"
            className="AboutMore max-w-6xl mx-auto flex flex-col md:flex-row lg:justify-between gap-y-10 gap-x-8 px-4 md:px-12 items-center mt-16 scroll-mt-20"
        >
            <div className="illustrasion lg:max-w-[424px]" data-aos="fade-right" data-aos-duration="1000">
                <img src={AboutImage} alt="AboutMoreImage" />
            </div>
            <div className="flex flex-col gap-y-8 lg:max-w-lg text-[#433E3E]">
                <h2 className="text-4xl lg:text-5xl font-bold" data-aos="fade-up" data-aos-duration="1000">
                    About Us
                </h2>
                <p className="text-sm lg:text-base lg:leading-relaxed" data-aos="fade-left" data-aos-duration="1000">
                    PT. Protonema was established on July 3, 2013. Protonema was founded by Mr. Mochamad Taat Baryanto
                    who also served as the Company's President Director. Protonema is engage in IT Services company
                    focusing on Business Consulting, Manage Operation, and Software Development.
                </p>
            </div>
        </section>
    );
};

export default AboutMore;
