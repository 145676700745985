import React from "react";
import Logo from "../../assets/Footer/KhidmaLogo.png";
import ".././Footer/Footer.css";

const FooterKhidma = () => {
    return (
        <section className="footer-section-2">
            <div className="flex flex-col gap-y-16 py-10">
                <div className="grid grid-cols-1 gap-y-10 md:grid-cols-2 lg:grid-cols-3 gap-x-4 md:gap-x-8 lg:gap-x-5 xl:gap-x-24 mx-4 md:mx-16 lg:mx-24">
                    <div className="flex flex-col gap-y-5">
                        <div className="header-content-footer">
                            <img src={Logo} alt="logo-protonema" className="w-40" />
                        </div>
                        <div className="content-footer flex flex-col gap-y-2">
                            <p className="text-base">
                                Khidma adalah pelayanan penyedia jasa kebutuhan Jemaah Haji selama berada di Tanah Suci
                                Makkah.
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-5 lg:gap-y-10">
                        <div className="header-content-footer">
                            <h2 className="text-lg font-bold title-footer">OTHERS</h2>
                        </div>
                        <div className="content-footer flex flex-col gap-y-2">
                            <a  href="/khidma/" className="cursor-pointer hover:text-[#557C55]">Tentang Khidma</a>
                            <a href="/khidma/kebijakan-privasi" className="cursor-pointer hover:text-[#557C55]">Kebijakan Privasi</a>
                            <a href="/khidma/syarat-dan-ketentuan" className="cursor-pointer hover:text-[#557C55]">Syarat dan Ketentuan</a>
                        </div>
                    </div>


                    <div className="flex flex-col gap-y-5 lg:gap-y-10">
                        <div className="header-content-footer">
                            <h2 className="text-lg font-bold title-footer">CONTACT INFO</h2>
                        </div>
                        <div className="content-footer flex flex-col gap-y-2">
                            <p>
                                L'Avenue Office Tower, 12th Floor Unit C, Jl. Raya Pasar Minggu, Kav.16 Pancoran,
                                Jakarta Selatan DKI Jakarta. 12780
                            </p>
                            <p>
                                <span className="gmap">
                                    <a href={"https://maps.app.goo.gl/pxcFTz4pEcccH4VaA"}>Visit Google Map</a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="text-center text-[#595454]">
                    &copy; 2024 Your{" "}
                    <a href="/" className="copyright">
                        Protonema
                    </a>{" "}
                    All rights reserved.
                </div>
            </div>
        </section>
    );
};

export default FooterKhidma;
